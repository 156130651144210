import React from 'react';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styles from './hero.module.css';

const ImageBackground = styled(BackgroundImage)`
  width: 100%;
  background-size: cover;
  height: 60vh;

  /* override the default margin for sibling elements  */
  + * {
    margin-top: 0;
  }
`;

// const TextBox = styled('div')`
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   justify-content: flex-end;
//   padding: 0 5vw 2rem;
//   width: 100%;

//   @media (min-width: calc(550px + 10vw)) {
//     padding-left: calc((100vw - 550px) / 2);
//     padding-right: calc((100vw - 550px) / 2);
//   }

//   h1 {
//     text-shadow: 1px 1px 3px #eeddff66;
//     font-size: 2.25rem;
//   }

//   p,
//   a {
//     color: #222;
//     margin-top: 0;
//   }

//   a {
//     margin-top: 0.5rem;
//   }
// `;

export default (props) => {
  const { heroImg } = props;

  return (
    <StaticQuery
      query={graphql`
        {
          home: file(relativePath: { eq: "tyler-duston-sydney.jpg" }) {
            sharp: childImageSharp {
              fluid(maxWidth: 3080, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          contact: file(relativePath: { eq: "contact-us.jpg" }) {
            sharp: childImageSharp {
              fluid(maxWidth: 3080, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}

      render={data => {
        return (
          <ImageBackground
            tag="section"
            fluid={data[heroImg].sharp.fluid}
            fadeIn="soft"
            imgStyle={{ objectFit: 'contain' }}
            className={heroImg === 'contact' ? styles.heroContact : styles.heroHome}
          >
            {/* {heroImg === 'contact' ?
              <TextBox>
                <h2>Contact Us</h2>
              </TextBox>
              : ''
            } */}
          </ImageBackground>
        )
      }}
    />
  );
}
