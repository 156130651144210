import React from 'react';
import styles from './Icons.module.css';
import emailLogo from '../../images/email.png';
import mobileLogo from '../../images/mobile.png';
import { LABELS } from './Constants';

const Icons = () => {
  return (
    <div className={styles.icons}>
      <div className={styles.iconContainer}>
        <img
          className={styles.icon}
          src={mobileLogo}
          alt='call us'
        />
        <a
          href="tel:+61278059274"
          className={styles.contactAnchor}
          target="_blank"
          rel="noopener noreferrer"
        >
          {LABELS.PHONE}
        </a>
      </div>
      <div className={styles.iconContainer}>
        <img
          className={styles.icon}
          src={emailLogo}
          alt='email us'
        />
        <a
          href="mailto:reactitinfo@gmail.com"
          className={styles.contactAnchor}
          target="_blank"
          rel="noopener noreferrer"
        >
          {LABELS.EMAIL}
        </a>
      </div>

    </div>
  );
};

export default Icons;
