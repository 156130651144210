export const LABELS = {
  COMPANY_NAME: 'REACT IT PTY LTD',
  HOME_WELCOME_MSG: 'Your one stop shop for the comprehensive React and Node JS Software Solutions. '+
  'Software Development is our strength and passion. ' +
  'We expect our clients to expect more from us in terms of smart mobile responsive web sites and cross platform mobile app solutions. ',
  HOME_TEXT: 'In today\'s digital age, a good website makes your business transition from local to truly global. ' +
  'A fully functional modern website should make you money even when you are not in the office. ' +
  'We are a group of technology passionate professionals who have strong industry experience in making customers dreams come true with smart mobile/web solutions. ',
  SLOGAN: 'Get a quote for your dream website',
  HOME_FOOTER: 'So if you have ever been wondering about owning a website, hit contact us for a competitive quote.',
  OFFICE: 'Registered Office',
  ADDRESS_LINE_1: '3 Good Street',
  ADDRESS_LINE_2: 'Sydney NSW 2150',
  ADDRESS_LINE_3: 'Australia',
  FOOTER_TEXT: '© REACT IT PTY LTD ABN 73 636 248 630',
  DISCLAIMER: 'Copyright All rights reserved.',
  PROJECT: 'Got a project?',
  TALK: 'Let’s talk',
  PHONE: '02 7805 9274',
  EMAIL: 'reactitinfo@gmail.com',
};

