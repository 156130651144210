import React from 'react';
import { LABELS } from './Constants';
import styles from './Footer.module.css';
import Icons from './Icons';

const Footer = () => {
  return (
    <section id='footer' className={styles.footerSection}>
      <div className={styles.footerContainer}>
        <div className={styles.projectSection}>
          <div className={styles.project}>
            {LABELS.PROJECT}
          </div>
          <div className={styles.talk}>
            <a
              href="tel:+61278059274"
              className={styles.contactAnchor}
              target="_blank"
              rel="noopener noreferrer"
            >
              {LABELS.TALK}
            </a>
          </div>
        </div>
        <div className={styles.iconSection}>
          <Icons />
        </div>
      </div>

      <div className={styles.disclaimerSection}>
        <div className={styles.footerLinks}>
          {LABELS.FOOTER_TEXT}
        </div>
        <div className={styles.footerLinks}>
          {LABELS.DISCLAIMER}
        </div>
      </div>
    </section>
  );
};

export default Footer;
